import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { createURLParams } from "../videoUtils";
import { setLanguage } from "../store/languageSlice";
import { fetchCustomizeData } from "../store/customizeSlice";
import { useDispatch } from "react-redux";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function AllVideo({ videoInfo }) {
  const { t } = useTranslation();
  const query = useQuery();
  const lang = useMemo(() => query.get("lang"), [query]);
  const dispatch = useDispatch();

  const [category, setCategory] = useState([]);
  let isFetch = true;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${window.location.origin}/video-material/category.json`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const jsonData = await response.json();
        setCategory(jsonData);
      } catch (error) {
        console.error("Error fetching JSON data:", error);
      }

      if (lang) {
        dispatch(setLanguage(lang));
      }
      dispatch(fetchCustomizeData());
    };
    if (isFetch) {
      fetchData();
      isFetch = false;
    }
  }, []);

  return (
    <>
      <h3 className="u-headline4 u-mb16p">
        <img
          alt={"Document"}
          src={`${window.location.origin}/video-material/img/search-icon.png`}
          style={{
            paddingRight: "10px",
            verticalAlign: "middle",
            display: "inline-flex",
            width: "32px",
          }}
        />
        {t("Home.allVideo")}
      </h3>
      <div>
        {category.map((categoryInfo) => (
          <div className="u-mt16p u-mb16p">
            <h4 className="u-headline5 u-mb8p">{categoryInfo.name}</h4>
            <ul className="c-thumbnail-command-list row row-cols-2 row-cols-sm-4 row-cols-md-5">
              {Object.entries(videoInfo)
                .filter(([itemKey, itemData]) => {
                  console.log(itemData.tags);
                  return itemData.tags === categoryInfo.key;
                })
                .map(([key, data]) => (
                  <li key={key}>
                    <div>
                      <a
                        href={`${
                          window.location.origin
                        }/watch/${key}${createURLParams({ lang: lang })}`}
                      >
                        <img
                          src={`${window.location.origin + data.thumbnail}`}
                          alt={key}
                          loading="lazy"
                          className="u-fit"
                        />
                      </a>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
}
